<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">Determine the chemical name based on the chemical formulae given below:</p>

      <p class="mb-3">
        a)
        <chemical-latex :content="formulaA" />
      </p>
      <v-text-field v-model="inputs.compoundA" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        b)
        <chemical-latex :content="formulaB" />
      </p>
      <v-text-field v-model="inputs.compoundB" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        c)
        <chemical-latex :content="formulaC" />
      </p>
      <v-text-field v-model="inputs.compoundC" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        d)
        <chemical-latex :content="formulaD" />
      </p>
      <v-text-field v-model="inputs.compoundD" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        e)
        <chemical-latex :content="formulaE" />
      </p>
      <v-text-field v-model="inputs.compoundE" outlined class="mb-1" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question343',
  components: {ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        compoundA: null,
        compoundB: null,
        compoundC: null,
        compoundD: null,
        compoundE: null,
        compoundF: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    formulaA() {
      if (this.versionVariableA.value === 1) {
        return 'Rb2O'; // rubidium oxide
      } else if (this.versionVariableA.value === 2) {
        return 'Na2O'; // sodium oxide
      } else if (this.versionVariableA.value === 3) {
        return 'Li2O'; // lithium oxide
      } else if (this.versionVariableA.value === 4) {
        return 'K2O'; // potassium oxide
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    formulaB() {
      if (this.versionVariableB.value === 1) {
        return 'P4O6'; // tetraphosphorus hexoxide
      } else if (this.versionVariableB.value === 2) {
        return 'CS2'; // carbon disulfide
      } else if (this.versionVariableB.value === 3) {
        return 'S2Cl2'; // disulfur dichloride
      } else if (this.versionVariableB.value === 4) {
        return 'SeS2'; // selenium disulfide
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    formulaC() {
      if (this.versionVariableC.value === 1) {
        return 'K3P'; // potassium phosphide
      } else if (this.versionVariableC.value === 2) {
        return 'Ag2S'; // silver(I) sulfide or silver sulfide
      } else if (this.versionVariableC.value === 3) {
        return 'Na2Se'; // sodium selenide
      } else if (this.versionVariableC.value === 4) {
        return 'Ca3P2'; // calcium phosphide
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    formulaD() {
      if (this.versionVariableD.value === 1) {
        return 'Ca(ClO3)2'; // calcium chlorate
      } else if (this.versionVariableD.value === 2) {
        return 'Mg(ClO2)2'; // magnesium chlorite
      } else if (this.versionVariableD.value === 3) {
        return 'Ca(ClO4)2'; // calcium perchlorate
      } else if (this.versionVariableD.value === 4) {
        return 'Sr(ClO4)2'; // strontium perchlorate
      } else {
        return '';
      }
    },
    versionVariableE() {
      return this.taskState.getValueBySymbol('versionVariableE').content;
    },
    formulaE() {
      if (this.versionVariableE.value === 1) {
        return 'TiCl4'; // titanium(IV) chloride
      } else if (this.versionVariableE.value === 2) {
        return 'MnO2'; // manganese(IV) oxide
      } else if (this.versionVariableE.value === 3) {
        return 'Fe(NO3)3'; // iron(III) nitrate
      } else if (this.versionVariableE.value === 4) {
        return 'TiO2'; // titanium(IV) oxide
      } else {
        return '';
      }
    },
  },
};
</script>
